@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Arial, Helvetica, sans-serif;
}

@layer base {
  :root {
    --background: #F9FAFB;
    --foreground: #303943;
    --card: #ECEEF1;
    --card-foreground: #303943;
    --popover: #526172;
    --popover-foreground: #F9FAFB;
    --primary: #D0D5DC;
    --primary-foreground: #303943;
    --secondary: #B5BEC8;
    --secondary-foreground: #303943;
    --muted: hsl(210, 2%, 18%);
    --muted-foreground: hsl(215.4, 16.3%, 46.9%);
    --accent: hsl(210, 40%, 96.1%);
    --accent-foreground: hsl(222.2, 47.4%, 11.2%);
    --destructive: hsl(0, 84.2%, 60.2%);
    --destructive-foreground: hsl(210, 40%, 98%);
    --border: hsl(214.3, 31.8%, 91.4%);
    --input: hsl(214.3, 31.8%, 91.4%);
    --ring: hsl(222.2, 84%, 4.9%);
    --chart-1: hsl(12, 76%, 61%);
    --chart-2: hsl(173, 58%, 39%);
    --chart-3: hsl(197, 37%, 24%);
    --chart-4: hsl(43, 74%, 66%);
    --chart-5: hsl(27, 87%, 67%);
    --radius: 0.5rem;
    --sidebar-background: #F9FAFB;
    --sidebar-foreground: #303943;
    --sidebar-primary: hsl(240, 5.9%, 10%);
    --sidebar-primary-foreground: hsl(0, 0%, 98%);
    --sidebar-accent: hsl(240, 4.8%, 95.9%);
    --sidebar-accent-foreground: hsl(240, 5.9%, 10%);
    --sidebar-border: #303943;
    --sidebar-ring: hsl(217.2, 91.2%, 59.8%);
    --topnav-background: #ECEEF1;
    --topnav-foreground: #303943;
    --topnav-primary: #303943;
    --topnav-primary-foreground: hsl(0, 0%, 98%);
    --topnav-accent: hsl(240, 4.8%, 95.9%);
    --topnav-accent-foreground: hsl(240, 5.9%, 10%);
    --topnav-border: #303943;
    --topnav-ring: hsl(217.2, 91.2%, 59.8%);
  }
  .dark {
    --background: hsl(222.2, 84%, 4.9%);
    --foreground: hsl(210, 40%, 98%);
    --card: hsl(222.2, 84%, 4.9%);
    --card-foreground: hsl(210, 40%, 98%);
    --popover: hsl(222.2, 84%, 4.9%);
    --popover-foreground: hsl(210, 40%, 98%);
    --primary: hsl(210, 40%, 98%);
    --primary-foreground: hsl(222.2, 47.4%, 11.2%);
    --secondary: hsl(217.2, 32.6%, 17.5%);
    --secondary-foreground: hsl(210, 40%, 98%);
    --muted: hsl(217.2, 32.6%, 17.5%);
    --muted-foreground: hsl(215, 20.2%, 65.1%);
    --accent: hsl(217.2, 32.6%, 17.5%);
    --accent-foreground: hsl(210, 40%, 98%);
    --destructive: hsl(0, 62.8%, 30.6%);
    --destructive-foreground: hsl(210, 40%, 98%);
    --border: hsl(217.2, 32.6%, 17.5%);
    --input: hsl(217.2, 32.6%, 17.5%);
    --ring: hsl(212.7, 26.8%, 83.9%);
    --chart-1: hsl(220, 70%, 50%);
    --chart-2: hsl(160, 60%, 45%);
    --chart-3: hsl(30, 80%, 55%);
    --chart-4: hsl(280, 65%, 60%);
    --chart-5: hsl(340, 75%, 55%);
    --sidebar-background: hsl(240, 5.9%, 10%);
    --sidebar-foreground: hsl(240, 4.8%, 95.9%);
    --sidebar-primary: hsl(224.3, 76.3%, 48%);
    --sidebar-primary-foreground: hsl(0, 0%, 100%);
    --sidebar-accent: hsl(240, 3.7%, 15.9%);
    --sidebar-accent-foreground: hsl(240, 4.8%, 95.9%);
    --sidebar-border: hsl(240, 3.7%, 15.9%);
    --sidebar-ring: hsl(217.2, 91.2%, 59.8%);
    --topnav-background: hsl(240, 5.9%, 10%);
    --topnav-foreground: hsl(240, 4.8%, 95.9%);
    --topnav-primary: hsl(224.3, 76.3%, 48%);
    --topnav-primary-foreground: hsl(0, 0%, 100%);
    --topnav-accent: hsl(240, 3.7%, 15.9%);
    --topnav-accent-foreground: hsl(240, 4.8%, 95.9%);
    --topnav-border: hsl(240, 3.7%, 15.9%);
    --topnav-ring: hsl(217.2, 91.2%, 59.8%);
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.nbsection a {
  @apply transition-transform duration-200 ease-out;
  text-decoration: underline;
}

.nbsection a:hover, .nbsection a:focus, .nbsection a:active {
  @apply scale-125;
}

dl {
  @apply p-4 bg-card text-card-foreground rounded-lg;
}

dt {
  @apply font-bold first:mt-0;
}


dt::after {
  content: ':';
}

#content-main {
  @apply bg-background text-card-foreground w-screen max-w-4xl;
}

.nbsection {
  @apply prose-sm md:prose-lg dark:prose-invert max-w-none first:pt-0;
}

.nbsection li {
  @apply my-0;
}

.nbsection ul > li {
  @apply list-disc;
}

.nbsection ol > li {
  @apply list-decimal;
}

li > ul, li > ol {
  @apply ml-4 my-0;
}

.nbsection li > p {
  @apply first:mt-0 last:mb-0;
}

.nbsection .full-width-image {
  width: 100%;
}

.nbsection code {
  word-break: break-all;
}

blockquote {
  @apply ml-2 border-l-4 border-primary;
}

.codeblock table.dataframe {
  @apply border border-slate-600;
}

.codeblock table.dataframe th {
  @apply bg-slate-300 text-slate-800;
}

.codeblock table.dataframe tr {
  @apply even:bg-slate-100 odd:bg-slate-200 hover:bg-slate-300 hover:text-slate-800;
}

.codeblock table.dataframe th,
.codeblock table.dataframe td {
  @apply p-2;
}

.heading-largest {
  @apply text-3xl font-bold md:text-5xl;
}
